.menu {
    color: "#6b6b6b";
    /* font-weight: 500; */
    /* font-size: 14px; */
    /* align-items: center; */
    /* display: flex; */
    /* border-color: "#DDDDDD"; */
    /* border-width: 2px; */
    /* border-style: solid; */
    /* height: 60px; */
  }
  
  .menu:hover {
    /* background-color: blue; */
    color: #d71921;
    background-color: #eceff4;
    /* height: "45px"; */
  }
  
  
  .hoverSubMenu {
    /* display: none; */
  }
  

  .sc::-webkit-scrollbar {
    width: 16px;
  }
  
  .sc::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .sc::-webkit-scrollbar-thumb {
    background-color: #d6dee1; 
    border-radius: 10px;
    border: 6px solid transparent;
    background-clip: content-box;
    
  }


.buttonAction:hover {
  font-weight: 600;
  /* border-bottom:solid 3px rgba(166,72,83,.7); */
  border-bottom:solid 3px rgba(107,107,107,.7);
  /* background-color: #d71921; */
  cursor: pointer;
}

.buttonAction {
  border-bottom: solid 3px rgba(0,0,0,.2);
}

.tabCustom {
  color: red;
}

.tabCustom.active {
  /* color: blue; */
  color: #d71921;
}

.nav-link {
  color: #6b6b6b;
}

a {
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: transparent;
}
a:hover {
  text-decoration: none;
}

.noCopy {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.avt_logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiSvgIcon-root { display: none; }



